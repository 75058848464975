export default function() {
  document.querySelectorAll('form').forEach(function(elem) {
    elem.reset();
    elem.addEventListener('submit', function(e) {
      let cancel = true;
      this.querySelectorAll('input, select, textarea').forEach(function(elem) {
        if (!elem.checkValidity()) {
          cancel = false;
        } else {
          elem.parentNode.classList.remove('invalid');
        }
      });
      if (!cancel) {
        if (navigator.vibrate) {
          navigator.vibrate(1000);
        }
        e.stopPropagation();
        e.preventDefault();
      }
      return cancel;
    }, false);
  });
  document.querySelectorAll('textarea').forEach(function(elm) {
    elm.style.height = 32 + 'px';
    elm.addEventListener('input', function() {
      let minHeight = 32;
      let maxHeight = 200;
      let height = maxHeight;

      if (this.scrollHeight < maxHeight) {
        height = this.scrollHeight;
      }

      if (height < minHeight) {
        height = minHeight;
      }

      if (this.clientHeight < height) {
        this.style.height = height + 'px';
      } else {
        while (this.scrollHeight <= this.clientHeight && this.clientHeight >= minHeight) {
          this.style.height = (this.clientHeight - 1)  + 'px';
        }
        if (this.clientHeight >= minHeight) {
          this.style.height = (this.clientHeight + 1)  + 'px';
        } else {
          this.style.height = minHeight  + 'px';
        }
      }
      
    });
 
  });

  document.querySelectorAll('input, select, textarea').forEach(function(element) {
    element.addEventListener('focus', function() {
      this.parentNode.classList.add('not-empty');
    }, false);
    element.addEventListener('blur', function() {
      if (this.checkValidity()) {
        this.parentNode.classList.remove('invalid');
      }
      if (this.parentNode.classList.contains('required') && this.value == '') {
        this.parentNode.classList.add('invalid');
      }
      if (this.value == '') {
        this.parentNode.classList.remove('not-empty');
      }
      if (this.getAttribute('name') === 'emailAddress') {
        validateMyEmail();
      }
    }, false);
    element.addEventListener('invalid', function() {
      if (this.parentNode.querySelector('.error').getAttribute('data-invalid')) {
        this.parentNode.querySelector('.error').innerHTML = this.parentNode.querySelector('.error').getAttribute('data-invalid');
      }
      this.parentNode.classList.add('invalid');
    }, false);
  });

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
  function validateMyEmail() {
    var result = $(".email-error");
    var email = $("input[name='emailAddress']").val();
  
    if (email !== '') {
      if (validateEmail(email)) {
        result.hide();
      } else {
        $("input[name='emailAddress'] + .error").css('opacity', '0');
        result.show();
      }
    }
    return false;
  }
}
